#achievement {
  padding: 40px 24px 40px 32px;
  background: #333;
  color: #fff;
  @include md() {
    padding: 40px 16px 40px 24px; }
  @include sp() {
    padding: 40px 8px 40px 16pxpx; } }

.achievement_inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  padding-bottom: 64px;
  @include md() {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 40px; }
  @include sp() {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 32px; } }

.achievement_item {
  position: relative;
  z-index: 1;
  padding-bottom: 16px;
  background: #777;
  box-shadow: 2px 2px 8px rgba(256,256,256,0.4);
  color: $text-color;
  transition: 0.15s ease-out;
  &:hover {
    cursor: pointer;
    &::before {
      top: -6px;
      left: -6px; }
    .achievement_item_image,
    .achievement_item_tag,
    .achievement_item_heading {
      transform: translate(-6px, -6px); } }
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 2px 2px 8px rgba(256,256,256,0.4);
    transition: 0.15s ease-out; }
  &::after {
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    display: block;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%) scale(0); }
  &.is_modal {
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    &::after {
      transform: translate(-50%, -50%) scale(1); } } }

.achievement_item_image {
  display: block;
  width: 100%;
  max-width: 320px;
  margin: 0 auto 8px;
  transition: 0.15s ease-out;
  transform: translate(-8px, -8px); }

.achievement_item_tag {
  margin-bottom: 4px;
  padding: 0 16px;
  transition: 0.15s ease-out;
  transform: translate(-8px, -8px); }

.achievement_item_heading {
  padding: 0 16px;
  font-weight: 700;
  transition: 0.15s ease-out;
  transform: translate(-8px, -8px); }

.achievement_modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: {} }
