.menu_trigger {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: block;
  cursor: pointer;
  transition: opacity 0.2s linear;
  background: rgba($white, 0.4);
  border-radius: 50%;
  &:hover {
    opacity: 0.8; }
  span {
    display: block;
    height: 4px;
    border-radius: 40px;
    background-color: $primary-color;
    position: absolute;
    right: 9px;
    top: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    &:nth-child(1) {
      width: 32px;
      margin-top: 12px;
      z-index: 9; }
    &:nth-child(2) {
      width: 24px;
      margin-top: 24px; }
    &:nth-child(3) {
      width: 16px;
      margin-top: 36px; } }
  &.is_active {
    background: none;
    .menu {
      opacity: 1;
      height: 400px;
      width: 400px; }

    span:nth-child(2) {
      overflow: visible; }

    span:nth-child(1), span:nth-child(3) {
      z-index: 100;
      transform: rotate(45deg);
      background-color: $white;
      width: 32px; }

    span:nth-child(1) {
      transform: rotate(45deg) translateY(9px) translateX(9px); }

    span:nth-child(2) {
      height: 400px;
      width: 400px;
      right: -160px;
      top: -140px;
      border-radius: 50%;
      background-color: rgba($primary-color, 0.8); }

    span:nth-child(3) {
      transform: rotate(-45deg) translateY(-8px) translateX(8px); }

    li {
      margin-right: 168px; } }

  @include sp() {
    &:hover {
      opacity: 1; } } }


.menu {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  height: 0;
  width: 0;
  right: 0;
  top: 0;
  position: absolute;
  background-color: rgba($primary-color, .8);
  border-radius: 50%;
  z-index: 18;
  overflow: hidden;
  li {
    display: block;
    float: right;
    clear: both;
    height: auto;
    margin-right: -160px;
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &:first-child {
      margin-top: 180px; }

    &:nth-child(1) {
      -webkit-transition-delay: 0.05s; }

    &:nth-child(2) {
      -webkit-transition-delay: 0.10s; }

    &:nth-child(3) {
      -webkit-transition-delay: 0.15s; }

    &:nth-child(4) {
      -webkit-transition-delay: 0.20s; }

    &:nth-child(5) {
      -webkit-transition-delay: 0.25s; }

    a {
      color: #fff;
      font-family: 'Lato', Arial, Helvetica, sans-serif;
      font-size: 16px;
      width: 100%;
      display: block;
      float: left;
      line-height: 40px; } } }
