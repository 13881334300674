#top {
  padding: 0; }

.top_inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.top_logo {
  position: relative;
  width: 240px;
  @include sp() {
    width: 160px; } }

.scroll-hint {
  position: absolute;
  left: 50%;
  bottom: 4px;
  z-index: 3;
  width: 9px;
  height: 100px;
  overflow: hidden;
  background: #cacaca;
  border-radius: 40px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: .2em;
  transform: translateX(-50%);
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    z-index: 1;
    width: 7px;
    height: 7px;
    background: #555;
    border-radius: 50%;
    animation: sdl 4.5s 1.5s infinite;
    opacity: 0; }
  @include sp() {
    .scroll-hint {
      bottom: 160px;
      left: 5%!important;
      height: 64px; } } }

@keyframes sdl {
  0% {
    transform: translateY(-16px);
    opacity: 0; }
  20% {
    transform: translateY(0);
    opacity: 0; }
  50% {
    opacity: 1; }
  80% {
    transform: translateY(100px);
    opacity: 0; }
  100% {
    transform: translateY(100px);
    opacity: 0; } }
