.animation {
  transition: 0.3s ease-out; }

.animation_slideup {
  transform: translateY(16px);
  opacity: 0;
  &.is_active {
    transform: translateY(0);
    opacity: 1; } }


.animation_delay {
  &:nth-child(2) {
    transition-delay: 0.1s; }
  &:nth-child(3) {
    transition-delay: 0.2s; }
  &:nth-child(4) {
    transition-delay: 0.3s; }
  &:nth-child(5) {
    transition-delay: 0.4s; }
  &:nth-child(6) {
    transition-delay: 0.5s; }
  &:nth-child(7) {
    transition-delay: 0.6s; }
  &:nth-child(8) {
    transition-delay: 0.7s; }
  &:nth-child(9) {
    transition-delay: 0.8s; }
  &:nth-child(10) {
    transition-delay: 0.9s; }
  &:nth-child(11) {
    transition-delay: 1s; } }
