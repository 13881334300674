.splash {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #cacaca;
  overflow: hidden;
  animation: splash 2s ease-out .5s forwards;
  pointer-events: none;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: splash_bg 1.5s ease-out .8s forwards; } }

.splash_logo {
  position: relative;
  width: 240px;
  svg {
    width: 100%;
    animation: splash_logo 1s ease-out 0s forwards; }
  @include sp() {
    width: 160px; } }

@keyframes splash {
  0%,
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes splash_bg {
  0% {
    transform: scale(0);
    background: transparent; }

  25% {
    transform: scale(0);
    background: transparent; }

  100% {
    transform: scale(2);
    background: #fff;
    display: none; } }

@keyframes splash_logo {
  0% {
    color: transparent; }

  25% {
    color: transparent; }

  100% {
    color: $primary-color; } }
