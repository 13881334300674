#mean {
  padding: 40px 8px;
  background: #333;
  color: #fff; }

.mean_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  text-align: center; }

.mean_branch {
  position: relative;
  padding: 16px;
  //background: url(../images/bg-logo-default.png)
  //background-size: contain
  //background-position: center
  //background-repeat: no-repeat
  color: #fff;
  &.bg-none {
    margin: 0 32px;
    padding: 0;
    background: none;
    .mean_branch_content {
      margin: 0;
      line-height: 1.2; } }
  @include sp() {
    padding: 12px;
    &.bg-none {
      margin: 0 20px; } } }

.bg-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  svg:not(:root) {
    overflow: visible; } }

.logo-icon-1 {
  fill: #251e1c; }

.logo-icon-bg {
  animation: logo-bg 4s infinite;
  transform-origin: 50% 50%;
  fill: #352e2c;
  stroke: #555; }

.mean_branch_heading {
  position: relative;
  font-size: 24px;
  font-family: gill-sans-nova,sans-serif;
  font-weight: 700;
  @include sp() {
    font-size: 20px; } }

.mean_branch_content {
  position: relative;
  margin: 0;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 18px;
  @include sp() {
    font-size: 14px;
    letter-spacing: -0.01em; } }

.mean_detail {
  margin-bottom: 40px;
  text-align: center;
  @include sp() {
    letter-spacing: -0.01em; } }

@keyframes logo-bg {
  0% {
    opacity: 1;
    stroke-width: 0; }
  20% {
    opacity: 1;
    stroke-width: 0; }
  80%,
  100% {
    opacity: 0;
    stroke-width: 20px; } }
