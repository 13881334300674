@mixin md() {
  @media only screen and (max-width: $pc-width) {
    @content; } }

@mixin sp() {
  @media only screen and (max-width: $sp-width) {
    @content; } }

@mixin pc() {
  @media only screen and (min-width: $pc-width) {
    @content; } }
