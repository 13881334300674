#about {
  padding: 40px 8px;
  //background: #333
 }  //color: #fff

.about_inner {
  max-width: 960px;
  margin: auto; }

.about_item {
  display: flex;
  margin-bottom: 8px;
  padding: 0 32px;
  @include sp() {
    padding: 0 16px;
    font-size: 13px; } }

.about_heading {
  width: 120px;
  @include sp() {
    width: 96px; } }

.about_content {
  flex: 1;
  a {
    color: inherit;
    text-decoration: underline; } }

.about_map {
  margin: 40px 0 0;
  @include sp() {
    margin: 40px -8px 0;
    width: 100vw; } }
