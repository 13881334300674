#jobs {
  padding: 40px 24px 40px 32px;
  //background: #dadada
  @include md() {
    padding: 40px 16px 40px 24px; }
  @include sp() {
    padding: 40px 8px 40px 16pxpx; } }

.jobs_inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  padding-bottom: 64px;
  @include md() {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 40px; }
  @include sp() {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 32px; } }

.jobs_item {
  position: relative;
  z-index: 1;
  background: #333;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.4);
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.4); } }

.jobs_item_heading {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 120px;
  padding: 16px;
  background: linear-gradient(120deg, #595858, #000);
  transform: translate(-8px, -8px);
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  span {
    position: relative; }
  &::before {
    content: '';
    position: absolute;
    right: 4px;
    bottom: 4px;
    width: 80%;
    height: 80%;
    background: url(../images/logo-f-g.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    opacity: 0.6; }
  @include md() {
    min-height: 100px;
    font-size: 20px; }
  @include sp() {
    min-height: 80px;
    font-size: 18px; } }

.jobs_item_content {
  padding: 16px;
  font-size: 14px;
  transform: translate(-8px, -8px);
  &.have-link {
    position: relative;
    padding-bottom: 60px;
    min-height: 220px; } }

.jobs_item_link {
  @include hover();
  position: absolute;
  bottom: 16px;
  left: 50%;
  display: table;
  margin: 16px auto 8px;
  padding: 8px 24px;
  background: $text-color;
  border-radius: 40px;
  color: $background-color;
  transform: translateX(-50%); }
