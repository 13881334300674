html, body {
  width: 100%;
  overflow-x: hidden;
}

body {
  font-size: 16px;
  font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "Yu Gothic", sans-serif;
  line-height: 2;
  letter-spacing: 0.05em;
  position: relative;
  color: #555;
  background: #fff;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
}

a {
  text-decoration: none;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  box-sizing: border-box;
  cursor: default;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

nav ol,
nav ul {
  list-style: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ffff00;
  color: #000000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

::-moz-selection {
  background-color: #b3d4fc;
  color: #000000;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #000000;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[hidden] {
  display: none;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-hidden="false"][hidden] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[aria-hidden="false"][hidden]:focus {
  clip: auto;
}

[aria-disabled] {
  cursor: default;
}

footer {
  padding: 8px;
  background: #bcbcbc;
}

.copyrights {
  margin: 24px 0 16px;
  font-size: 12px;
  text-align: center;
}

.menu_trigger {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: block;
  cursor: pointer;
  transition: opacity 0.2s linear;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}

.menu_trigger:hover {
  opacity: 0.8;
}

.menu_trigger span {
  display: block;
  height: 4px;
  border-radius: 40px;
  background-color: #251E1C;
  position: absolute;
  right: 9px;
  top: 0;
  overflow: hidden;
  transition: all 0.4s ease;
}

.menu_trigger span:nth-child(1) {
  width: 32px;
  margin-top: 12px;
  z-index: 9;
}

.menu_trigger span:nth-child(2) {
  width: 24px;
  margin-top: 24px;
}

.menu_trigger span:nth-child(3) {
  width: 16px;
  margin-top: 36px;
}

.menu_trigger.is_active {
  background: none;
}

.menu_trigger.is_active .menu {
  opacity: 1;
  height: 400px;
  width: 400px;
}

.menu_trigger.is_active span:nth-child(2) {
  overflow: visible;
}

.menu_trigger.is_active span:nth-child(1), .menu_trigger.is_active span:nth-child(3) {
  z-index: 100;
  transform: rotate(45deg);
  background-color: #fff;
  width: 32px;
}

.menu_trigger.is_active span:nth-child(1) {
  transform: rotate(45deg) translateY(9px) translateX(9px);
}

.menu_trigger.is_active span:nth-child(2) {
  height: 400px;
  width: 400px;
  right: -160px;
  top: -140px;
  border-radius: 50%;
  background-color: rgba(37, 30, 28, 0.8);
}

.menu_trigger.is_active span:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px) translateX(8px);
}

.menu_trigger.is_active li {
  margin-right: 168px;
}

@media only screen and (max-width: 768px) {
  .menu_trigger:hover {
    opacity: 1;
  }
}

.menu {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  height: 0;
  width: 0;
  right: 0;
  top: 0;
  position: absolute;
  background-color: rgba(37, 30, 28, 0.8);
  border-radius: 50%;
  z-index: 18;
  overflow: hidden;
}

.menu li {
  display: block;
  float: right;
  clear: both;
  height: auto;
  margin-right: -160px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.menu li:first-child {
  margin-top: 180px;
}

.menu li:nth-child(1) {
  -webkit-transition-delay: 0.05s;
}

.menu li:nth-child(2) {
  -webkit-transition-delay: 0.10s;
}

.menu li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
}

.menu li:nth-child(4) {
  -webkit-transition-delay: 0.20s;
}

.menu li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
}

.menu li a {
  color: #fff;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-size: 16px;
  width: 100%;
  display: block;
  float: left;
  line-height: 40px;
}

section {
  padding: 40px 0;
}

.section_wrapper {
  max-width: 1080px;
  margin: auto;
}

.section_heading {
  margin-bottom: 40px;
  font-family: gill-sans-nova,sans-serif;
  font-size: 32px;
  font-weight: 700;
}

.section_heading span {
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .section_heading {
    font-size: 24px;
  }
}

.section_subheading {
  margin-bottom: 24px;
  font-family: gill-sans-nova,sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.section_subheading span {
  display: inline-block;
}

.jobs_inner + .section_subheading {
  margin-top: 16px;
}

@media only screen and (max-width: 768px) {
  .section_subheading {
    font-size: 20px;
  }
}

.animation {
  transition: 0.3s ease-out;
}

.animation_slideup {
  transform: translateY(16px);
  opacity: 0;
}

.animation_slideup.is_active {
  transform: translateY(0);
  opacity: 1;
}

.animation_delay:nth-child(2) {
  transition-delay: 0.1s;
}

.animation_delay:nth-child(3) {
  transition-delay: 0.2s;
}

.animation_delay:nth-child(4) {
  transition-delay: 0.3s;
}

.animation_delay:nth-child(5) {
  transition-delay: 0.4s;
}

.animation_delay:nth-child(6) {
  transition-delay: 0.5s;
}

.animation_delay:nth-child(7) {
  transition-delay: 0.6s;
}

.animation_delay:nth-child(8) {
  transition-delay: 0.7s;
}

.animation_delay:nth-child(9) {
  transition-delay: 0.8s;
}

.animation_delay:nth-child(10) {
  transition-delay: 0.9s;
}

.animation_delay:nth-child(11) {
  transition-delay: 1s;
}

@media only screen and (max-width: 768px) {
  .is_pc {
    display: none;
  }
}

.is_mobile {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .is_mobile {
    display: block;
  }
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

#about {
  padding: 40px 8px;
}

.about_inner {
  max-width: 960px;
  margin: auto;
}

.about_item {
  display: flex;
  margin-bottom: 8px;
  padding: 0 32px;
}

@media only screen and (max-width: 768px) {
  .about_item {
    padding: 0 16px;
    font-size: 13px;
  }
}

.about_heading {
  width: 120px;
}

@media only screen and (max-width: 768px) {
  .about_heading {
    width: 96px;
  }
}

.about_content {
  flex: 1;
}

.about_content a {
  color: inherit;
  text-decoration: underline;
}

.about_map {
  margin: 40px 0 0;
}

@media only screen and (max-width: 768px) {
  .about_map {
    margin: 40px -8px 0;
    width: 100vw;
  }
}

#achievement {
  padding: 40px 24px 40px 32px;
  background: #333;
  color: #fff;
}

@media only screen and (max-width: 1080px) {
  #achievement {
    padding: 40px 16px 40px 24px;
  }
}

@media only screen and (max-width: 768px) {
  #achievement {
    padding: 40px 8px 40px 16pxpx;
  }
}

.achievement_inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  padding-bottom: 64px;
}

@media only screen and (max-width: 1080px) {
  .achievement_inner {
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .achievement_inner {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 32px;
  }
}

.achievement_item {
  position: relative;
  z-index: 1;
  padding-bottom: 16px;
  background: #777;
  box-shadow: 2px 2px 8px rgba(255, 255, 255, 0.4);
  color: #555;
  transition: 0.15s ease-out;
}

.achievement_item:hover {
  cursor: pointer;
}

.achievement_item:hover::before {
  top: -6px;
  left: -6px;
}

.achievement_item:hover .achievement_item_image,
.achievement_item:hover .achievement_item_tag,
.achievement_item:hover .achievement_item_heading {
  transform: translate(-6px, -6px);
}

.achievement_item::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 2px 2px 8px rgba(255, 255, 255, 0.4);
  transition: 0.15s ease-out;
}

.achievement_item::after {
  content: '';
  position: fixed;
  top: 50%;
  left: 50%;
  display: block;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%) scale(0);
}

.achievement_item.is_modal {
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.achievement_item.is_modal::after {
  transform: translate(-50%, -50%) scale(1);
}

.achievement_item_image {
  display: block;
  width: 100%;
  max-width: 320px;
  margin: 0 auto 8px;
  transition: 0.15s ease-out;
  transform: translate(-8px, -8px);
}

.achievement_item_tag {
  margin-bottom: 4px;
  padding: 0 16px;
  transition: 0.15s ease-out;
  transform: translate(-8px, -8px);
}

.achievement_item_heading {
  padding: 0 16px;
  font-weight: 700;
  transition: 0.15s ease-out;
  transform: translate(-8px, -8px);
}

.achievement_modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#jobs {
  padding: 40px 24px 40px 32px;
}

@media only screen and (max-width: 1080px) {
  #jobs {
    padding: 40px 16px 40px 24px;
  }
}

@media only screen and (max-width: 768px) {
  #jobs {
    padding: 40px 8px 40px 16pxpx;
  }
}

.jobs_inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  padding-bottom: 64px;
}

@media only screen and (max-width: 1080px) {
  .jobs_inner {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .jobs_inner {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 32px;
  }
}

.jobs_item {
  position: relative;
  z-index: 1;
  background: #333;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
}

.jobs_item::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
}

.jobs_item_heading {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 120px;
  padding: 16px;
  background: linear-gradient(120deg, #595858, #000);
  transform: translate(-8px, -8px);
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
}

.jobs_item_heading span {
  position: relative;
}

.jobs_item_heading::before {
  content: '';
  position: absolute;
  right: 4px;
  bottom: 4px;
  width: 80%;
  height: 80%;
  background: url(../images/logo-f-g.png);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  opacity: 0.6;
}

@media only screen and (max-width: 1080px) {
  .jobs_item_heading {
    min-height: 100px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .jobs_item_heading {
    min-height: 80px;
    font-size: 18px;
  }
}

.jobs_item_content {
  padding: 16px;
  font-size: 14px;
  transform: translate(-8px, -8px);
}

.jobs_item_content.have-link {
  position: relative;
  padding-bottom: 60px;
  min-height: 220px;
}

.jobs_item_link {
  transition: .3s ease;
  position: absolute;
  bottom: 16px;
  left: 50%;
  display: table;
  margin: 16px auto 8px;
  padding: 8px 24px;
  background: #555;
  border-radius: 40px;
  color: #fff;
  transform: translateX(-50%);
}

.jobs_item_link:hover {
  opacity: .8;
}

@media only screen and (max-width: 1080px) {
  .jobs_item_link:hover {
    opacity: 1;
  }
}

#mean {
  padding: 40px 8px;
  background: #333;
  color: #fff;
}

.mean_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  text-align: center;
}

.mean_branch {
  position: relative;
  padding: 16px;
  color: #fff;
}

.mean_branch.bg-none {
  margin: 0 32px;
  padding: 0;
  background: none;
}

.mean_branch.bg-none .mean_branch_content {
  margin: 0;
  line-height: 1.2;
}

@media only screen and (max-width: 768px) {
  .mean_branch {
    padding: 12px;
  }
  .mean_branch.bg-none {
    margin: 0 20px;
  }
}

.bg-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-logo svg:not(:root) {
  overflow: visible;
}

.logo-icon-1 {
  fill: #251e1c;
}

.logo-icon-bg {
  animation: logo-bg 4s infinite;
  transform-origin: 50% 50%;
  fill: #352e2c;
  stroke: #555;
}

.mean_branch_heading {
  position: relative;
  font-size: 24px;
  font-family: gill-sans-nova,sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .mean_branch_heading {
    font-size: 20px;
  }
}

.mean_branch_content {
  position: relative;
  margin: 0;
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .mean_branch_content {
    font-size: 14px;
    letter-spacing: -0.01em;
  }
}

.mean_detail {
  margin-bottom: 40px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .mean_detail {
    letter-spacing: -0.01em;
  }
}

@keyframes logo-bg {
  0% {
    opacity: 1;
    stroke-width: 0;
  }
  20% {
    opacity: 1;
    stroke-width: 0;
  }
  80%,
  100% {
    opacity: 0;
    stroke-width: 20px;
  }
}

#top {
  padding: 0;
}

.top_inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.top_logo {
  position: relative;
  width: 240px;
}

@media only screen and (max-width: 768px) {
  .top_logo {
    width: 160px;
  }
}

.scroll-hint {
  position: absolute;
  left: 50%;
  bottom: 4px;
  z-index: 3;
  width: 9px;
  height: 100px;
  overflow: hidden;
  background: #cacaca;
  border-radius: 40px;
  font-size: 14px;
  line-height: 1;
  letter-spacing: .2em;
  transform: translateX(-50%);
}

.scroll-hint::after {
  content: '';
  position: absolute;
  top: 0;
  left: 1px;
  z-index: 1;
  width: 7px;
  height: 7px;
  background: #555;
  border-radius: 50%;
  animation: sdl 4.5s 1.5s infinite;
  opacity: 0;
}

@media only screen and (max-width: 768px) {
  .scroll-hint .scroll-hint {
    bottom: 160px;
    left: 5% !important;
    height: 64px;
  }
}

@keyframes sdl {
  0% {
    transform: translateY(-16px);
    opacity: 0;
  }
  20% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  80% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}

.splash {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #cacaca;
  overflow: hidden;
  animation: splash 2s ease-out .5s forwards;
  pointer-events: none;
}

.splash::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: splash_bg 1.5s ease-out .8s forwards;
}

.splash_logo {
  position: relative;
  width: 240px;
}

.splash_logo svg {
  width: 100%;
  animation: splash_logo 1s ease-out 0s forwards;
}

@media only screen and (max-width: 768px) {
  .splash_logo {
    width: 160px;
  }
}

@keyframes splash {
  0%,
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes splash_bg {
  0% {
    transform: scale(0);
    background: transparent;
  }
  25% {
    transform: scale(0);
    background: transparent;
  }
  100% {
    transform: scale(2);
    background: #fff;
    display: none;
  }
}

@keyframes splash_logo {
  0% {
    color: transparent;
  }
  25% {
    color: transparent;
  }
  100% {
    color: #251E1C;
  }
}
