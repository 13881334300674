section {
  padding: 40px 0; }

.section_wrapper {
  max-width: 1080px;
  margin: auto; }

.section_heading {
  margin-bottom: 40px;
  font-family: gill-sans-nova,sans-serif;
  font-size: 32px;
  font-weight: 700;
  span {
    display: inline-block; }
  @include sp() {
    font-size: 24px; } }

.section_subheading {
  margin-bottom: 24px;
  font-family: gill-sans-nova,sans-serif;
  font-size: 24px;
  font-weight: 700;
  span {
    display: inline-block; }
  .jobs_inner+& {
    margin-top: 16px; }
  @include sp() {
    font-size: 20px; } }
