html, body {
  width: 100%;
  overflow-x: hidden; }

body {
  font-size: 16px;
  font-family: $sans-serif;
  // mincho
  // font-family: $serif
  line-height: 2;
  letter-spacing: 0.05em;
  position: relative;
  color: $text-color;
  background: $background-color;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  @include sp() {
    font-size: 14px; } }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0; }

a {
  text-decoration: none; }
